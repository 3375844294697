import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        name: "LandingPage",
        component: () => import('./components/views/landing_page/LandingPage.vue'),
        path: "/",
    },
    {
        name: "LogIn",
        component: () => import('./components/views/login/Log_In.vue'),
        path: '/login/:url',
        props: true
    },
    {
        name: 'ManageDomains',
        path: '/home',
        component: () => import('./components/views/home/HomePage.vue')
    },
    {
        name: 'AddDomain',
        path: '/add/domain',
        component: () => import('./components/views/domains/add/AddDomain.vue')
    },
    // {
    //     name: "HomePage",
    //     component: () => import('./components/views/domains/manage/ManageDomain.vue'),
    //     path: "/home",
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router;